@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@font-face {
  font-family: 'kyri_kapsregular';
  src: url('/assets/fonts/kyri_kaps-webfont.woff2') format('woff2'),
      url('/assets/fonts/kyri_kaps-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

$wayred:#e6613e;
$wayblue: #4295CF;
$waygreen: #17AF88;
$waypurple: #8378A3;
$offblack: #313639;



body {
    margin: 0;
    font-family: 'kyri_kapsregular', sans-serif;
    /*
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
      */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  @import url('https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css');
  
  .canvas-area {
   // border: 1px solid #ccc;
    position: sticky;
    top: 120px;
  }
  
  .canvas-area .panel {
    padding: 0;// 2rem;
    background-color: var(--light);
    position: relative;
  }
  
  .exported-image {
    width: 100%;
    border: 1px solid black;
  }
  #exportContainer{
    position: relative;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
   /* background: url('/assets/backgrounds/gold-frame.png'); */
   // background-size: cover;

   &::before{
    content: '';
    width: 100%;
    height: 100%;
    background-color: white;
    position: absolute;
    top:0;
    left:0;

   }
  }



  .react-sketch-canvas {
   // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    position: absolute;
    top: 0;
    left: 0;
    background: none;
  }

  .bg-tag .wpb{
    display: block;
  }

  .wpb{
    display: none;
    position: absolute;
    z-index: 400;
    bottom: 10px;
    left: 10px;
   // transform: translate(-50%, -50%);
    width: 50%;
    max-width: 200px;
  }
  
  .sketchingTime {
    font-weight: 200;
    font-size: 3rem;
  }

  .main-header{
      align-items: center;
      justify-content: space-around;
      height: 180px;
      margin-bottom: 40px;
      padding: 0 0px 0 30px;
    
  }

  .title-col{
    flex-direction: column;
  }
  .wpb-game{
    margin: 0px auto 10px;
    position: relative;
    width: 210px;
  }
  

  h1 {
    font-family: 'kyri_kapsregular', sans-serif;
    font-style: normal;
    font-weight: 900;
    color: $offblack;
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 48px;
    line-height: 1em;
    letter-spacing: -4px;
    letter-spacing: 2.25px;
    text-shadow: 0 2px 4px rgba(0,0,0,.5);
    text-align: center;
  }

  h1.book{
    font-size: 32px;
  }

  h3{
    text-align: center;
    font-family: 'kyri_kapsregular', sans-serif;
  }


  .picker {
  //  position: relative;
   // position: absolute;
  }
  
  .swatch {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: 2px solid #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin: 4px 0;
    position: relative;
    overflow: hidden;

    

    .use-rainbow__swatches{

      width: 4px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    outline: 1px solid #ffffffbd;
    flex-direction: column;


      div{
        width: 100%;
        height: 5px;
      }

    }

    
  }
  
  .popover {
    position: absolute;
    top: 10px;
    left: calc(100% - 210px);
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    z-index: 99999;
  }
  
  .stroke-value{
    font-size: 12px;
    padding: 10px;
    border: 1px solid white;
    background-color: white;
    border-radius: 6px;
    margin: 4px 0;
    text-align: center;
    font-weight: bold;
    color: #010101;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  
  }


.draw-section{
  width: 75%;
  margin: 0 auto;
  max-width: 850px;
}

 .guide-row{
   // justify-content: space-evenly;
   // position: sticky;
  //top: 0;
 // margin-top: 40px;
 // background-color: rgba(255,255,255,.5);
//  flex-direction: column;
//  margin-top: 40px;
  //opacity: 0;

  width: 75%;
    flex-direction: row;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 30px;
    height: 100px;
    justify-content: center;
    


    > div{
        width: 100%;
        max-width: 180px;
        height: 100%;
        opacity: 0;
        transition: opacity .7s ease-out;
        
        

        h3{
            text-align: center;
            text-decoration: underline;
            font-size: 18px;
            margin: 0;
        }

        img{
            width: 100%;
            height: 100%;
        }

       
    }

    > div:nth-of-type(0){
      transition-delay: .3s;
    }
    > div:nth-of-type(1){
      transition-delay: .6s;
    }
    > div:nth-of-type(2){
      transition-delay: .9s;
    }
    > div:nth-of-type(3){
      transition-delay: 1.2s;
    }
    > div:nth-of-type(4){
      transition-delay: 1.5s;
    }
    > div:nth-of-type(5){
      transition-delay: 1.8s;
    }
 }

 .spacer{
    // height: 300px;
     display: none;
 }
 
  
  .logoBox{
  
    cursor: pointer;
    position: fixed;
     z-index: 999;
     top: 10px;
     left: 10px;
 }
   .logoHolder{
     position: relative;
     display: flex;
     flex-direction: row;
     flex-wrap: overflow-wrap;
     justify-content: center;
     align-items: center;
     width: 80px;
     height: 80px;
   }
 
     .logoCircle{
       position: absolute;
       width: 80%;
       height: 80%;
       background: purple;
       border-radius: 50%;
       margin-left: -4px;
       cursor: pointer;
       box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
       will-change: width, height;
     }
 
     .letter{
       width: 60px;
       height: 60px;
       background: white;
       border-radius: 100px;
       background-size: contain;
       /* border: 1px solid pink;*/
       will-change: transform, opacity;
     }
   
  .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      background-size: cover;
      background-position: center;
      will-change: opacity;
    }
    
 .bg2{
      background-size: cover;
      background-position: center;
      will-change: opacity;
    }


    .arrow-btn {
      width: 70px;
      height: 70px;
     // background-image: url('./assets/images/arrow.png');
      border-radius: 35px;
      background-color: #4295cf;
      background-repeat: no-repeat;
      background-position: 65% 50%;
      background-size: 40px;
      margin: 10px;
      border: none;
  
      transform: scale(1);
      transition: all .3s ease-out;
      cursor: pointer;
  
  
      &:hover{
        transform: scale(1.1)
      }
    }
.mode-intro{
  .react-sketch-canvas{
    pointer-events: none;
  }
}

.hide-intro{

  .intro-text{
    opacity: 0;
  }
}

.click-start{
   text-decoration: none;
   
   color: dodgerblue;
   background-color: white;
   padding: 10px;
   margin-top: 20px;
   border-radius: 8px;
   border: 1px solid dodgerblue;
   cursor: pointer;
  // animation: fadeIn 5s;
   animation: 1s ease 2.5s normal forwards 1 fadeIn;
   opacity: 0;

   &:hover{
    color: red;
   }


}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.sound-button{
width: 40px;
height: 40px;
margin: 4px auto;
border-radius: 6px;
border-color: white;
border-width: 0;
background-color: #ffffff82;
cursor: pointer;
position: absolute;
top: 10px;
right: 10px;
z-index: 99999;
transform: translateY(0px);
transition: transform .2s ease-out;
 

  svg{
    width: 20px;
    height: 20px;
    margin: 0 auto;
    transform: translateX(-1px);
    //fill: white;
  }

  &:hover{
    background-color: #f0ffcb;
    transform: translateY(1px);
  }
}

.mode-background{
/*
  .intro-text{
    opacity: 0;
  }
*/
  .react-sketch-canvas{
    pointer-events: none;
  }

  .MenuBar button:nth-of-type(1){
    background-color: #84ffaa;
    svg{
      fill: #31b293;
    }
  }

  .ControlBar{

    transform: translateX(-100px);

    .bkgdBar{
  
       transform: translateX(90px);
    }

    .slider-col{
      transform: translateX(-70px);
   }
}

  .guide-row{
   // opacity: 0;
  }

}

.mode-drawing{
  .intro-text{
    opacity: 0;
    display: none;
  }
  .react-sketch-canvas{
    pointer-events: all;
  }

  .MenuBar button:nth-of-type(2){
    background-color: #84ffaa;
    svg path{
      stroke: #31b293;
    }
  }

  .ControlBar{

       transform: translateX(0px);

       .bkgdBar{
  
          transform: translateX(-10px);
      }

       .slider-col{
          transform: translateX(-6px);
       }
  }


}

.reveal-steps{
  .guide-row{
    > div{
      opacity: 1;
    }
  }
}

.mode-snapshot{
  .intro-text{
    opacity: 0;
    display: none;
  }

  .react-sketch-canvas{
    pointer-events: all;
  }

  .MenuBar button:nth-of-type(3){
    background-color: #84ffaa;
    svg{
      fill: #31b293
    }
  }

  .ControlBar{

   // transform: translateX(0px);

   .bkgdBar{
  
    transform: translateX(-10px);
 }

    .slider-col{
       transform: translateX(-70px);
    }
}

  .guide-row{
    > div{
      opacity: 1;
    }
  }
}

#popover-over{
  position: absolute;
  top: 50px;
  left: 300px;
  z-index: 999999;
}

.intro-text{
  opacity: 1;
  transition: opacity .3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 // outline: 1px solid pink;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  pointer-events: none;

  h2{
    text-align: center;
  }
}

.trails-main div:nth-last-of-type(1){
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.trails-main div{
  display: inline-block;
  margin: 0 2px;
  font-size: calc(1.325rem + .9vw);
  text-shadow: 2px 1px 1px white;
}
    
.MenuBar{

    z-index: 9999;
    display: flex;
    flex-direction: row;
   // padding: 10px 2px 10px 10px;
    padding: 10px 10px 10px 10px;
 //  transform: translateY(-50%);
    border-top-right-radius: 8px 8px;
    border-bottom-right-radius: 8px 8px;
 

    position: absolute;
    left:0;
    bottom: -60px;
    align-items: center;
    justify-content: center;
    width: 100%;
    pointer-events: none;

    .button-row{
        display: flex;
         flex-direction: row;

    }


    button{
        border: 2px solid #6adbc0;
        background-color: white;
        transition: background .3s ease-out, transform .2s ease-out;
        width: 80px;
        height: 80px;
        margin: 8px;// auto;
        border-radius: 6px;
      //  border-color: rgb(255, 255, 255);
      //  border-width: 0;
        background-color: white;
        cursor: pointer;
        pointer-events: all;
        transform: translateY(0px);

        &:hover{
            background-color: rgb(240, 255, 203);
            transform: translateY(2px);
        }

        svg{
            width: 40px;
            height: 40px;
            margin: 0 auto;
        }
    }
} 


.ControlBar{
   // background-color: rgba(0, 0, 0, 1);
 ////   background-color: #ffe484;
   // position: fixed;
  //  right: 0;
  //  left: 0;
   // top: 50%;
    z-index: -1;// 999;
    display: flex;
    flex-direction: row;
  //  outline: green 1px solid;

    transition: all .35s cubic-bezier(.47,1.64,.41,.8);
    transform: translateX(-100px);
    
 //  transform: translateY(-50%);
  ///  border-top-right-radius: 8px 8px;
  ///  border-bottom-right-radius: 8px 8px;
 

    position: absolute;
    right: -80px;
    top: 0;
    width: 80px;

    .bkgdBar{
      position: absolute;
      top:0;
      left: 0;
      transition: all .3s cubic-bezier(.47,1.64,.41,.8);
      transform: translateX(-10px);

      background-color: #ffe484;
      border-top-right-radius: 8px 8px;
      border-bottom-right-radius: 8px 8px;
      padding: 10px 10px 10px 20px;

    }

    .drawBar{
      background-color: #ffe484;
      border-top-right-radius: 8px 8px;
      border-bottom-right-radius: 8px 8px;
      padding: 10px;
      transform: translateX(0px);
    }

    .button-col{
        display: flex;
         flex-direction: column;
    }

    .color-holder{
      position: relative;

    }

    .picker {
      position: relative;
    }

    .size{
      position: absolute;
    color: white;
    top: 50%;
    left: 50%;
      z-index: 20;
      transform: translate(-50%, -50%);
      text-shadow: 1px 1px 1px #0000005e;
      pointer-events: none;
      padding-right: 2px;
    }

    .slider-col{
       display: flex;
       flex-direction: column;
       align-items: center;
       transition: all .4s cubic-bezier(.47,1.64,.41,.8);

  
        padding: 10px 8px 10px 14px;
        background-color: #ffc821;
        transform: translateX(-6px);
        border-top-right-radius: 8px 8px;
        border-bottom-right-radius: 8px 8px;
        z-index: -1;

        button{
          border-radius: 20px;
          margin: 10px;
        }

        &.hide-slider{
          transform: translateX(-100px);
        }

    }

    button{
        width: 40px;
        height: 40px;
        margin: 4px auto;
        border-radius: 6px;
        border-color: rgb(255, 255, 255);
        border-width: 0;
        background-color: white;
        cursor: pointer;
        transform: translateY(0px);
        transition: transform .2s ease-out;

        &:hover{
            background-color: rgb(240, 255, 203);
            transform: translateY(1px);
        }

        svg{
            width: 20px;
            height: 20px;
            margin: 0 auto;
        }
    }
}  

.book-feature{
  padding: 40px;
  border-radius: 6px;
  background-color: #6adcc0;
  margin-top: 100px;

  .col-9{
    align-items: center;
    display: flex;
  }

  img{
    width: 240px;
    margin-top: -80px;
  }

  ul {
    padding: 0;
    li{
    list-style-type: none;

      span{
      // font-style: italic;
      // letter-spacing: 4px;;
      margin-right: 10px;
      }
    }
    li:last-of-type{
      margin-top: 20px;

       a{
            border-bottom: 3px solid #95ffa6;
            text-decoration: none;
            color: white;
            font-weight: bold;
            letter-spacing: .5px;

            &:hover{
              border-bottom: 3px solid white;
            }
        }
      
    }
  }
}

///////////////

@media (max-width: 820px) { 
  .p-5{
    //padding: 2rem!important;
  }

  .main-header{
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 0;
  }

  .star{
    position: absolute;
    top: 220px;
    right: 10%;//10px;
    z-index: -1;
  }

  .main-content{
    flex-direction: column;
  }

  .guide-row{
    width: 100%;
    flex-direction: row;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
    justify-content: center;

   
    
  }

  .guide-row .spacer{
    display: none;
  }

  .guide-row > div h3{
    font-size: 14px;
  }

  .draw-section{
    width: 75%;
    margin: 0 auto;
  }

  .logo{
    display: none;
  }

  .guide-row > div{
    opacity: 1;
  }

  .book-feature {

    margin-top: 100px;
  
    ul{
      margin-left: 40px;
    }
  }

  .book-feature img{
    width: 160%;
    max-width: 240px;
    margin-top: -80px;
    transform: translateX(-20px);
  }
/*
  .draw-section{
    width: 100%;
    padding: 0;
    margin-bottom: 120px;
  }

  .ControlBar{
    top: auto;
    bottom: -98px;
    left: 50%;
    right: auto;
    width: 100%;
    max-width: 460px;
    height: auto;
    flex-direction: column;
    padding: 2px 10px;
    border-radius: 0;
    border-bottom-left-radius: 8px 8px;
    border-bottom-right-radius: 8px 8px;
    transform: translateX(-50%);

    .button-col{
      flex-direction: row;
    margin: 0;
    padding: 0;
    width: 100%;
    justify-content: space-evenly;
    }

    .slider-col{
      padding: 0 2px;
      margin: 0 6px -2px;
    }

    
  }

  .book-feature ul{
    margin-left: 40px;
  }
*/
}

@media (max-width: 580px) { 

  .wpb{
    width: 70%;
  }
  

  .p-5{
    padding: 2rem!important;
  }

  .main-header{
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 0;
  }

  .star{
    position: absolute;
    top: 220px;
    right: 10px;
    z-index: -1;
  }

  .main-content{
    flex-direction: column;
  }

  .guide-row{
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
    margin-bottom: 40px;
  }

  .guide-row .spacer{
    display: none;
  }

  .guide-row > div h3{
    font-size: 14px;
  }

  .draw-section{
    width: 100%;
    padding: 0;
    height: auto;
    max-height: 400px;
    margin-bottom: 120px;
  }

  .mode-background {

      .ControlBar{
        transform: translate(0, 0px);

          .bkgdBar {
          
            transform: translate(-50%, 10px);
          }

          .drawBar{
            transform: translate(0, -60px);

            
          }

          .slider-col{
            transform: translate(0px, -240px);
          }
      }
  }

  .mode-drawing {

    .ControlBar{
      transform: translate(0, 0px);

        .bkgdBar {
        
          transform: translate(-50%, -60px);
        }

        .drawBar{
          transform: translate(0, 20px);

          
        }

        .slider-col{
          transform: translate(0px, 10px);
        }
    }
}

.mode-snapshot {

  .ControlBar{
    transform: translate(0, 0px);

      .bkgdBar {
      
        transform: translate(-50%, -60px);
      }

      .drawBar{
        transform: translate(0, -60px);

        
      }

      .slider-col{
        transform: translate(0px, -240px);
      }
  }
}

  .MenuBar{
    top: -60px;
    bottom: unset;
    transform: scale(.7);
  }

  .ControlBar{
    top: auto;
    bottom: -125px;
    left: 0;
    right: auto;
    width: 100%;
    height: auto;
    flex-direction: column;
    padding: 2px 6px;
    border-radius: 0;
    border-bottom-left-radius: 8px 8px;
    border-bottom-right-radius: 8px 8px;
   // outline: 1px solid red;
    transform: translateX(0);

    .bkgdBar{
      border-top-right-radius: 0;
      border-bottom-left-radius: 8px 8px;
      border-bottom-right-radius: 8px 8px;
      padding: 20px 10px 10px 10px;
      width: 120px;
      transform: translate(-50%, -60px);
      left: 50%;

      .button-col{
        flex-direction: row-reverse;
      }
    }

    .drawBar{
      transform: translate(0, -60px);
      transition: all .3s cubic-bezier(.47,1.64,.41,.8);
      border-top-right-radius: 0;
      border-bottom-right-radius: 8px 8px;
      border-bottom-left-radius: 8px 8px;

     
    }

    .slider-col{
      border-top-right-radius: 0;
      border-bottom-right-radius: 8px 8px;
      border-bottom-left-radius: 8px 8px;
      flex-direction: row;
      padding: 20px 10px 10px;

      &.hide-slider{
        transform: translate(0, -140px);
      }

      .color-holder{
        margin-right: 10px;
      }
    }
    

    .button-col{
      flex-direction: row;
    margin: 0;
    padding: 0;
    width: 100%;
    justify-content: space-evenly;
    }

    button svg{
      margin-left: -6px;
      margin-top: -2px;
     // border: 1px solid;
      fill: black;
    }

    .slider-col{
     // padding: 0 2px;
     // margin: 0 6px -2px;
    }

    
  }

  .book-feature{
    flex-direction: column;
    margin-top: 180px;

    .col-3, .col-9{
      width: 100%;

      ul{
        margin: 0;
        padding: 0;
        text-align: center;
      }
    }

    img{
      margin: -80px auto 20px;
      transform: translateX(0px);
    }

    ul{
      margin: 0;
      padding: 0;
      text-align: center;
    }
  }

}